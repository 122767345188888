<template>
  <div class="actCredentials">
    <Header title="账号凭证" />
    <div class="main">
      <div class="credentialsBox">
        <div class="qrcode">
          <div ref="qrCodeUrl"></div>
        </div>
        <div class="downloadUrl">{{ officialUrl }}</div>
        <div class="tipTitle">如果账号丢失:</div>
        <div class="tipContent">1.设置-2.找回账号-3.账号凭证找回（上传凭证/扫描凭证）</div>
      </div>
      <div class="saveBtn" @click="showTip">截图保存</div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import QRCode from 'qrcodejs2';
import { queryCertificate } from '@/api/mine';
import { getSessionItem } from '@/utils/longStorage';
import { Toast } from 'vant';
export default {
  name: 'ActCredentials',
  components: {
    Header,
  },
  data() {
    return {
      qrUrl: '', // 二维码地址
      userInfo: '', // 用户信息
      officialUrl: '', // 官网
      actCredentialsBg: require('@/assets/png/actCredentialsBg.png'),
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    let appConfig = JSON.parse(getSessionItem('appConfig'));
    appConfig.sourceList.forEach((i) => {
      if (i.type == 'LAND') {
        this.officialUrl = i.domain[0].url;
      }
    });
    this.queryCertificate();
  },
  methods: {
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 180.2,
          height: 180.2,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    async queryCertificate() {
      let ret = await this.$Api(queryCertificate);
      if (ret && ret.code == 200) {
        this.qrUrl = ret.data.content;
        this.generateCode(ret.data.content);
      }
    },
    showTip() {
      Toast('请手动截图保存账号凭证');
    },
  },
};
</script>

<style lang="scss" scoped>
.actCredentials {
  box-sizing: border-box;
  height: 100%;
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 20px;
    box-sizing: border-box;
    .credentialsBox {
      margin: 22px auto 0;
      height: 376.2px;
      width: 332px;
      overflow: hidden;
      position: relative;
      background: url('./../../../../assets/png/actCredentialsBg.png') no-repeat;
      background-size: 100% 100%;
      .qrcode {
        width: 180.2px;
        height: 180.2px;
        margin: 54px auto 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .downloadUrl {
        width: 225px;
        font-size: 13px;
        margin: 12.8px auto 0;
        color: #797979;
        text-align: center;
      }
      .tipTitle {
        position: absolute;
        left: 11px;
        bottom: 48.2px;
        font-size: 11px;
        color: #797979;
      }
      .tipContent {
        position: absolute;
        left: 11px;
        bottom: 21.2px;
        font-size: 11px;
        color: #797979;
      }
    }
  }
  .saveBtn {
    position: fixed;
    left: 50%;
    bottom: 34px;
    transform: translateX(-50%);
    width: 271px;
    height: 49px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    background-image: linear-gradient(to right, #f74a82, #fca984);
  }
}
</style>
